import { useProfileModal } from "@/pages/profile/utils/ProfileModalContext";
import { saveUpdatedUserProfile } from "@/pages/profile/utils/ProfileScreenUtils";
import { paths } from "@/routerPaths";
import { routerUrl } from "@/utils";
import { ls } from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { ss } from "@/utils/helpers/sessionStorage";
import { useQuery } from "@tanstack/react-query";
import { useNetworkState } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { useUnmount } from "usehooks-ts";
import { generateRandomString } from "../helpers/Utils";
import {
  OdienceEvent,
  OdienceUser,
  authenticateDirector,
  fetchEventInfo,
  fetchEvents,
  fetchPublicEventInfo,
  fetchPublicEvents,
} from "./useDirectorAuthentication";
import { useExponentialBackoff } from "./useExponentialBackoff";
import { useOdienceOrganization } from "./useOdienceOrganization";

export function useOdienceEvents({
  updateUserAvatar,
}: {
  updateUserAvatar: (avatar: string) => void;
}) {
  const { organizationId, embeddedMode } = useOdienceOrganization();
  const { updateUserDisplayName, updateUserEmail } = useProfileModal();
  const odienceUser = ls.getOdienceUser();
  const [authenticatedUser, setAuthenticatedUser] = useState(
    odienceUser ? JSON.parse(odienceUser) : null
  );
  const [objEvents, setObjEvents] = useState<OdienceEvent[] | null>(
    ls.getOdienceEvents()
  );
  const displayName = authenticatedUser?.name;
  const firstName = ls.getUserFirstName();
  const lastName = ls.getUserLastName();
  const emailInvite = ss.getEmail();
  const invitedId = ss.getInvite();
  const invitedEventId = ss.getInvitedEventId();
  const network = useNetworkState();
  const token = isProvisioned() ? ls.getDirectorToken() : "";

  const filterEvents = (events: OdienceEvent[]) => {
    return events.filter(
      (event) =>
        event.label !== "ended" &&
        (!embeddedMode ||
          (embeddedMode &&
            (!organizationId || organizationId === event.organization_id)))
    );
  };

  const getAuthenticatedUser = async () => {
    return (await authenticateDirector()) as OdienceUser;
  };

  const {
    isPending: eventsPending,
    refetch: refetchEvents,
    isFetching,
  } = useQuery({
    queryKey: ["odienceEvents"],
    queryFn: async () => {
      const provisioned = isProvisioned();

      if (provisioned) {
        const authenticatedUser = await getAuthenticatedUser();
        setAuthenticatedUser(authenticatedUser);
        if (authenticatedUser.avatar) {
          updateUserAvatar(authenticatedUser.avatar);
        }
      }

      const events = provisioned
        ? await fetchEvents()
        : await fetchPublicEvents();

      if (events) {
        ls.setOdienceEvents(events);
      }

      setObjEvents(events);
      return events;
    },
    staleTime: 0,
    refetchOnMount: "always",
  });

  const handleUpdateUser = async (updatedEmail: string) => {
    await saveUpdatedUserProfile(
      authenticatedUser,
      firstName,
      lastName,
      displayName,
      updatedEmail,
      emailInvite,
      invitedId,
      invitedEventId || ss.getLastEventId(),
      updateUserAvatar,
      updateUserDisplayName,
      updateUserEmail
    );
  };

  const deleteEvent = (eventId: string) => {
    setObjEvents(
      (previous) => previous && previous.filter((event) => event.id !== eventId)
    );
  };

  const updateEvent = async (eventId: string) => {
    let eventInfo;
    if (isProvisioned()) {
      eventInfo = await fetchEventInfo(eventId);
    } else {
      eventInfo = await fetchPublicEventInfo(eventId);
    }

    if (eventInfo) {
      setObjEvents(
        (previous) =>
          previous && [
            ...previous.filter((event) => event.id !== eventId),
            eventInfo,
          ]
      );
    }
  };

  const socketRef = useRef<SocketIOClient.Socket | null>(null);

  const connectSocket = async () => {
    if (!network.online) {
      console.log("Skipping socket connection: offline");
      return;
    }

    disconnectSocket();

    const url = `${routerUrl}/`;
    let userId = `guest-${generateRandomString(15)}`;

    if (isProvisioned()) {
      const authenticatedUser = await getAuthenticatedUser();
      setAuthenticatedUser(authenticatedUser);
      userId = authenticatedUser.msisdn;
    }

    socketRef.current = io(url, {
      transports: ["websocket"],
      query: { user_id: userId, token: ls.getDirectorToken() },
    });

    socketRef.current.on("Connected", () => {
      console.log("Connected to socket");
      stopReconnectSocketRetry();
    });

    socketRef.current.on("EventsListUpdateAvailable", refetchEvents);
    socketRef.current.on("EventUpdated", ({ id }) => updateEvent(id));
    socketRef.current.on("EventRemoved", ({ eventId }) => deleteEvent(eventId));
    socketRef.current.on("EventDeleted", ({ eventId }) => deleteEvent(eventId));
    socketRef.current.on("EventEnded", ({ eventId }) => deleteEvent(eventId));

    socketRef.current.on("UserEmailVerified", async (data) => {
      const updatedEmail = data.email;
      await handleUpdateUser(updatedEmail);
    });

    const handleError = (error: Error) => {
      console.error("Socket error:", error);
      reconnectSocket();
    };

    socketRef.current.on("connect_timeout", handleError);
    socketRef.current.on("connect_error", handleError);
    socketRef.current.on("disconnect", handleError);
    socketRef.current.on("error", handleError);
  };

  const disconnectSocket = () => {
    console.log("Disconnecting from socket");
    socketRef.current?.removeAllListeners();
    socketRef.current?.disconnect();
    socketRef.current = null;
  };

  const { run: reconnectSocket, stop: stopReconnectSocketRetry } =
    useExponentialBackoff(connectSocket, "infinite");

  useEffect(() => {
    void connectSocket();
    return () => {
      disconnectSocket();
      stopReconnectSocketRetry();
    };
  }, [token, network.online]);

  useUnmount(() => {
    disconnectSocket();
    stopReconnectSocketRetry();
  });

  return {
    objEvents: objEvents && filterEvents(objEvents),
    eventsPending,
    embeddedMode,
    isFetching,
    organizationId,
  };
}

export const generateEventListPath = (
  objEvent: OdienceEvent,
  embeddedMode: boolean
) => {
  ss.removeLastGroupId();
  ss.removeLastEventId();

  if (isProvisioned()) {
    // This logic is expected for now, hitting back in embedded mode from a private event should redirect to the list of events only from the organization event
    const organizationIdQuery =
      embeddedMode && objEvent && !objEvent.is_public
        ? `?organizationId=${objEvent.organization_id}`
        : "";

    return paths.odience + organizationIdQuery;
  } else {
    return paths.previewOdience;
  }
};
