import PopupOverlay from "@/components/shared/PopupOverlay";
import Provisioning from "@/pages/provisioning";
import { AnimatePresence, MotionConfig } from "motion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useProvisioningModal = ({
  onSuccessPathRedirect,
  fromOdience,
}: {
  onSuccessPathRedirect?: string;
  fromOdience: boolean;
}) => {
  const navigate = useNavigate();

  const [showProvisioning, setShowProvisioning] = useState(false);

  const handleOnProvisioningSuccess = () => {
    if (onSuccessPathRedirect) {
      void navigate(onSuccessPathRedirect, { viewTransition: true });
    }
    setShowProvisioning(false);
  };

  return {
    modal: showProvisioning && (
      <MotionConfig>
        <AnimatePresence>
          <PopupOverlay>
            <Provisioning
              fromOdience={fromOdience}
              onSuccess={handleOnProvisioningSuccess}
              onClose={() => setShowProvisioning(false)}
            />
          </PopupOverlay>
        </AnimatePresence>
      </MotionConfig>
    ),
    open: () => setShowProvisioning(true),
    close: () => setShowProvisioning(false),
  };
};

export default useProvisioningModal;
