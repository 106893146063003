import appleIosBadgeIcon from "@/assets/app-store.svg";
import metaStoreBadge from "@/assets/meta-store.svg";
import googleBadgeIcon from "@/assets/play-store.svg";
import { colors, odienceColors } from "@/styles/global.styles";
import { directorUrl } from "@/utils";
import {
  odienceAndroidDesktopUrl,
  odienceIOSDesktopUrl,
  odienceMetaUrl,
} from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";

type GetAppModalProps = {
  show: boolean;
  onCloseModal: () => void;
  objEvent: { id: string } | null;
};

const GetAppModal = ({ show, onCloseModal, objEvent }: GetAppModalProps) => {
  const qrCodeContainerRef = useRef<HTMLDivElement>(null);
  const directorUrlWithoutPort = new URL(directorUrl).hostname + "/o/";

  useEffect(() => {
    if (!show) return;

    const generateOdienceQrCode = () => {
      if (!qrCodeContainerRef.current) return;

      const width = qrCodeContainerRef.current.clientWidth;
      const height = qrCodeContainerRef.current.clientHeight;
      const url = objEvent
        ? `${directorUrlWithoutPort}${objEvent.id}?qr=1`
        : directorUrlWithoutPort;

      const qrCode = new QRCodeStyling({
        width,
        height,
        type: "svg",
        data: url,
        image: "/odience/odience_logo_red.png",
        dotsOptions: {
          color: odienceColors.pureBlack,
          type: "rounded",
        },
        backgroundOptions: {
          color: odienceColors.pureWhite,
        },
      });

      while (qrCodeContainerRef.current.firstChild) {
        qrCodeContainerRef.current.removeChild(
          qrCodeContainerRef.current.firstChild
        );
      }

      qrCode.append(qrCodeContainerRef.current);
    };

    generateOdienceQrCode();
  }, [show, objEvent, directorUrlWithoutPort]);

  return (
    <div css={[modalStyles, { visibility: show ? "visible" : "hidden" }]}>
      <div css={modalContentStyles}>
        <div css={headerStyles}>
          <div css={titleStyles}>GET THE ODIENCE APP</div>
          <button
            type="button"
            id="getAppModalCloseButton"
            className="directorCloseModalButton roundCloseButton ripple"
            onClick={onCloseModal}
            css={closeButtonStyles}
          >
            <img
              src="/odience/event/white-close.svg"
              alt="Close"
              css={{ width: "50%", padding: "0.2em" }}
            />
          </button>
        </div>
        <div css={centerContentStyles}>
          <div css={appContainerStyles}>
            <div css={contentTitleStyles}>
              <div css={contentTitleImageStyles}>
                <img
                  src="/odience/event/mobile_tablet.svg"
                  alt=""
                  css={{ height: "4em" }}
                />
              </div>
              <div css={contentTitleTextStyles}>
                For a better experience view on mobile or tablet
              </div>
            </div>
          </div>

          <div css={scanMeTextStyles}>SCAN ME with your phone</div>
          <div css={scanArrowStyles}>
            <img
              src="/odience/user/sort_down.svg"
              alt=""
              css={{ width: "1.25em", height: "1.25em" }}
            />
          </div>
          <div css={qrCodeAppDivStyles}>
            <div
              ref={qrCodeContainerRef}
              id="qrCodeSize"
              css={qrCodeSizeStyles}
            >
              <div
                id="qr-code-image-odience"
                css={{ height: "13em", width: "13em", objectFit: "contain" }}
              >
                {/* The generated QR code will be appended here */}
              </div>
            </div>
          </div>

          <div css={appStoreTextStyles}>Or check it out in the app stores</div>
          <div css={appButtonContainerStyles}>
            <div css={appButtonStyles}>
              <a
                href={odienceAndroidDesktopUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleBadgeIcon}
                  css={{ width: "100%", height: "6vh", minHeight: "3rem" }}
                />
              </a>
            </div>
            <div css={appButtonStyles}>
              <a
                href={odienceIOSDesktopUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleIosBadgeIcon}
                  css={{ width: "100%", height: "6vh", minHeight: "3rem" }}
                />
              </a>
            </div>
            <div css={appButtonStyles}>
              <a
                href={odienceMetaUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={metaStoreBadge}
                  css={{ width: "100%", height: "6vh", minHeight: "3rem" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetAppModal;

const modalStyles = css({
  visibility: "visible",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "visibility 0s, opacity 0.3s ease",
  position: "fixed",
  zIndex: 1000,
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
  overflow: "auto",
  backgroundColor: "rgb(0, 0, 0, 0.8)",
});

const modalContentStyles = css({
  width: "38.75em",
  height: "40em",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "2em",
  backgroundColor: "#141414",
  borderRadius: "16px",
});

const headerStyles = css({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: "2em",
  justifyContent: "center",
  position: "relative",
});

const titleStyles = css({
  color: colors.primaryTextColor,
  fontSize: "1.3rem",
  textAlign: "center",
  letterSpacing: "0.2em",
});

const closeButtonStyles = css({
  cursor: "pointer",
  marginLeft: "auto",
  marginRight: "1.25em",
  width: "2.75em",
  height: "2.75em",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  right: 0,
});

const centerContentStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
});

const appContainerStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const contentTitleStyles = css({
  fontSize: "0.875rem",
  width: "16.5em",
  display: "flex",
  alignItems: "center",
  flexFlow: "row",
  gap: 0,
  margin: 0,
});

const contentTitleImageStyles = css({
  width: "4em",
  height: "4em",
  marginRight: "1em",
  opacity: 0.6,
});

const contentTitleTextStyles = css({
  color: "#888888",
  fontSize: "1rem",
});

const scanMeTextStyles = css({
  paddingBottom: "1vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  color: "#888888",
  fontSize: "0.875rem",
});

const scanArrowStyles = css({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const qrCodeAppDivStyles = css({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
});

const qrCodeSizeStyles = css({
  width: "13em",
  height: "13em",
  boxSizing: "border-box",
  border: `5px solid ${colors.primaryTextColor}`,
  borderRadius: "8px",
  background: colors.primaryTextColor,
});

const appStoreTextStyles = css({
  margin: "1.25em 0 1em 0",
  width: "100%",
  textAlign: "center",
  color: "#888888",
});

const appButtonContainerStyles = css({
  display: "flex",
  flexDirection: "row",
  gap: "1vw",
  marginTop: "1.5vh",
  justifyContent: "center",
  alignItems: "center",
});

const appButtonStyles = css({
  width: "11.25em",
  height: "3em",
});
